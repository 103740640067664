import React, { ChangeEvent, FC } from "react";
import "./TextInputField.css";

interface TextInputFieldProps {
  label: string;
  placeholder: string;
  onChange: (value: string) => void;
}

const TextInputField: FC<TextInputFieldProps> = ({
  label,
  placeholder,
  onChange,
}) => {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className="textarea-div">
      <label>{label}</label>
      <textarea placeholder={placeholder} onChange={handleChange} />
    </div>
  );
};

export default TextInputField;

import React, { useState, FC, useEffect } from "react";
import { ReactComponent as StarFilled } from "../images/star-filled.svg";
import { ReactComponent as StarUnfilled } from "../images/star-unfilled.svg";

import "./StarRating.css";

interface StarRatingProps {
  rating: number; // Make sure this is included
  onRate: (rating: number) => void;
}

const StarRating: FC<StarRatingProps> = ({ rating, onRate }) => {
  const [currentRating, setCurrentRating] = useState<number>(rating);
  const [hover, setHover] = useState<number>(0);

  useEffect(() => {
    setCurrentRating(rating);
  }, [rating]);

  const handleClick = (ratingValue: number) => {
    setCurrentRating(ratingValue);
    onRate(ratingValue);
  };

  const handleMouseEnter = (ratingValue: number) => {
    setHover(ratingValue);
  };

  const handleMouseLeave = () => {
    setHover(0);
  };

  return (
    <div className="star-rating">
      {[1, 2, 3, 4, 5].map((starValue) => (
        <div
          key={starValue}
          onClick={() => handleClick(starValue)}
          onMouseEnter={() => handleMouseEnter(starValue)}
          onMouseLeave={handleMouseLeave}
        >
          {starValue <= (hover || currentRating) ? (
            <StarFilled className="filled" />
          ) : (
            <StarUnfilled className="unfilled" />
          )}
        </div>
      ))}
    </div>
  );
};

export default StarRating;
